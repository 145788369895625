<template>
  <div>
    <router-view/>
  </div>
</template>

<script>

export default {
  methods: {
    showNav(value) {
      console.log(value);
    },
  },

  components: {
  },
};
</script>

<style lang="scss">
  @import 'https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500&display=swap';
  //@import 'https://allyoucan.cloud/cdn/icofont/1.0.1/icofont.css';
  @import './assets/scss/main.scss';
</style>
